import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';
import env from './util/env';

const persistConfig = {
    key: 'root',
    version: 2,
    storage,
    blacklist: ['mapData', 'auth', 'clusterMap', 'filteredProjects', 'filteredPaginatedProjects'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        const defaultMiddleware = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                    'filteredProjects/completeFetchProjects',
                    'filteredPaginatedProjects/setProjects',
                    'filteredPaginatedProjects/updateProjects',
                    'mapData/completeFetchMapData',
                    'clusterMap/completeFetchAllPipes',
                ],
                ignoredPaths: ['mapData', 'clusterMap', 'filteredProjects', 'filteredPaginatedProjects'],
            },
        });
        if (env('ENVIRONMENT') === `development`) {
            return defaultMiddleware.concat(logger);
        } else {
            return defaultMiddleware;
        }
    },
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
