import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';

import App from './App';
import theme from './theme';
import { ToastContainer } from './util/utils';
import './App.css';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <App />
            <ToastContainer />
        </ChakraProvider>
    </React.StrictMode>,
);
