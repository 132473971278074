import { ReactFragment } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

import { selectUser } from '../reducers/auth';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import DelayedSpinner from './DelayedSpinner';
import { Paths } from '../util/constants';
import env from '../util/env';
import { userCanEdit } from '../util/utils';
import Header from './Header';
import { clearNextLocation, selectNextLocation } from '../reducers/nextLocation';

interface ProtectedRouteProps {
    children?: ReactFragment | JSX.Element;
    needsEditPermissions?: boolean;
}

const ProtectedRoute = ({ children, needsEditPermissions }: ProtectedRouteProps) => {
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const loginPath = env('ENVIRONMENT') == 'development' ? Paths.emailLogin : Paths.oktaLogin;
    const nextLocation = useAppSelector(selectNextLocation);

    if (user === null) {
        return <DelayedSpinner />;
    }

    if (user === false) {
        return <Navigate to={loginPath} replace state={{ from: location }} />;
    }

    if (needsEditPermissions && userCanEdit(user) == false) {
        return <Navigate to={Paths.home} />;
    }

    if (nextLocation) {
        if (nextLocation == location?.pathname) {
            dispatch(clearNextLocation());
        } else {
            return <Navigate to={nextLocation} />;
        }
    }

    return (
        <Flex flexFlow="column" height="100vh" overflow="hidden">
            <Header />
            {children}
        </Flex>
    );
};

export default ProtectedRoute;
