import WebMercatorViewport from 'viewport-mercator-project';

import { ViewportType } from '../types/reducers/mapData';
import { initialViewport } from './constants';

interface Props {
    bounds: [number, number, number, number];
    width: number;
    height: number;
}

const createViewPortFromBounds = ({ bounds, width, height }: Props): ViewportType => {
    const [minX, minY, maxX, maxY] = bounds;
    const centroid: [number, number] = [(maxX + minX) / 2, (maxY + minY) / 2];

    const { zoom }: { zoom: number } = new WebMercatorViewport({
        width: width,
        height: height,
    }).fitBounds(
        [
            [minX, minY],
            [maxX, maxY],
        ],
        {
            padding: 25, // Add twenty-five pixels worth of padding around the data extent
            offset: [0, 0],
        },
    );
    const { pitch, bearing, maxZoom, minZoom, padding } = initialViewport;
    return {
        width: 'auto',
        height: '100%',
        latitude: centroid[1],
        longitude: centroid[0],
        zoom: zoom,
        pitch: pitch,
        bearing: bearing,
        maxZoom: maxZoom,
        minZoom: minZoom,
        padding: padding,
    };
};

export default createViewPortFromBounds;
