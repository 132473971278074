import { ThunkDispatch } from 'redux-thunk';

import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from '../api';
import { NotificationsState, NotificationType, NotificationUpdate } from '../types/reducers/notifications';
import { ThunkType } from '../types/types';
import { getErrorMessage } from '../util/utils';

import type { RootState } from '../store';
import { createClearOnLogout } from './auth';
import { NOTIFICATION_STATUSES } from '../util/constants';

const initialState: NotificationsState = {
    data: [],
    loading: false,
    error: null,
};

const clearOnLogout = createClearOnLogout<NotificationsState>(initialState);

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        startFetchNotifications: () => ({
            data: [],
            loading: true,
            error: null,
        }),
        completeFetchNotifications: (state: NotificationsState, { payload }: PayloadAction<NotificationType[]>) => {
            state.data = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchNotifications: (state: NotificationsState, { payload }: PayloadAction<string>) => {
            state.data = [];
            state.loading = false;
            state.error = payload;
        },
        startUpdateNotificationStatus: () => ({
            data: [],
            loading: true,
            error: null,
        }),
        completeUpdateNotificationStatus: (
            state: NotificationsState,
            { payload }: PayloadAction<NotificationType[]>,
        ) => {
            state.data = payload;
            state.loading = false;
            state.error = null;
        },
        failUpdateNotificationStatus: (state: NotificationsState, { payload }: PayloadAction<string>) => {
            state.data = [];
            state.loading = false;
            state.error = payload;
        },
    },
    extraReducers: clearOnLogout,
});

export const {
    startFetchNotifications: startFetchNotifications,
    completeFetchNotifications: completeFetchNotifications,
    failFetchNotifications: failFetchNotifications,
    startUpdateNotificationStatus: startUpdateNotificationStatus,
    completeUpdateNotificationStatus: completeUpdateNotificationStatus,
    failUpdateNotificationStatus: failUpdateNotificationStatus,
} = notificationsSlice.actions;

export const fetchNotifications = (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(startFetchNotifications());
    try {
        const data = await api.getNotifications();
        dispatch(completeFetchNotifications(data));
    } catch (e: unknown) {
        const message: string = getErrorMessage(e, 'Failed to fetch notifications.');

        dispatch(failFetchNotifications(message));
    }
};

export const updateNotificationStatuses =
    (statusUpdates: NotificationUpdate[]): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startUpdateNotificationStatus());

        try {
            const data = await api.putNotificationStatuses(statusUpdates);
            dispatch(completeUpdateNotificationStatus(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Failed to fetch notifications.');
            dispatch(failUpdateNotificationStatus(message));
        }
    };

export const selectNotifications = ({ notifications: notifications }: RootState) => notifications.data;
export const selectUnreadNotificationsCount = ({ notifications: notifications }: RootState) =>
    notifications.data.filter(({ status }) => status === NOTIFICATION_STATUSES.new).length;
export const selectNotificationsLoading = ({ notifications }: RootState) => notifications.loading;
export const selectNotificationsError = ({ notifications }: RootState) => notifications.error;
export default notificationsSlice.reducer;
