import { ThunkDispatch } from 'redux-thunk';

import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from '../api';
import {
    HighCostFactorsType,
    AssetCategoryType,
    ProjectTypeType,
    ThunkType,
    AssetTypeType,
    BaseRateType,
} from '../types/types';
import {
    getErrorMessage,
    handleFieldErrors,
    userCanSaveProject,
    userIsValidator,
    getUniqueWeightLimitWarnings,
    projectCostLabel,
    userCanRevertCompletedProject,
} from '../util/utils';

import { RootState } from '../store';
import { DistrictType } from '../types/reducers/districts';
import {
    SelectedWeightsType,
    PipeDetails,
    ProjectDataType,
    ProjectWeightPayloadType,
    ProjectWeightsType,
    ProjectStatusType,
} from '../types/reducers/projects';
import { PipeFeatureType } from '../types/reducers/mapData';
import { createClearOnLogout } from './auth';
import {
    defaultProjectWeights,
    investmentCategories,
    investmentCategoryLabel,
    projectWeightLabels,
} from '../util/constants';
import { filter, pick, size } from 'lodash';
import { fetchFilteredProjects } from './filteredProjects';

interface ProjectState {
    data: ProjectDataType;
    loading: boolean;
    saving: boolean;
    submitting: boolean;
    selecting: boolean;
    updating: boolean;
    error: string | null;
    saved: boolean;
    page: number;
}

const initialState: ProjectState = {
    data: {
        district_name: '',
        name: '',
        description: '',
        status: 'DRAFT',
        project_type: 'Replace',
        asset_category: 'Water',
        cps_ref_num: undefined,
        sr_weight: defaultProjectWeights.sr_weight,
        srs_weight: defaultProjectWeights.srs_weight,
        sfp_weight: defaultProjectWeights.sfp_weight,
        wq_weight: defaultProjectWeights.wq_weight,
        ls_weight: defaultProjectWeights.ls_weight,
        l_weight: defaultProjectWeights.l_weight,
        weight_limits_warnings: [],
        total_impact_score: null,
        total_length: 0,
        rehab_pipe_pct: 0,
        created_by_id: null,
        created_by_email: '',
        universal_id: null,
        pipes: [],
        pipe_version: null,
        pipe_details: {},
        created_at: null,
        categories: [],
        extent: null,
        center: null,
    },
    loading: false,
    saving: false,
    submitting: false,
    selecting: false,
    updating: false,
    error: null,
    saved: false,
    page: 0,
};

const clearOnLogout = createClearOnLogout<ProjectState>(initialState);

const getMainLength = (mainlength: number | null) => (mainlength ? mainlength : 0);

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        startFetchProject: () => ({
            ...initialState,
            loading: true,
        }),
        completeFetchProject: (state: ProjectState, { payload }: PayloadAction<ProjectDataType>) => {
            state.data = payload;
            state.page = 5;
            state.loading = false;
            state.error = null;
            state.saved = true;
        },
        failFetchProject: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.data = initialState.data;
            state.loading = false;
            state.error = payload;
        },
        clearProject: () => initialState,
        clearProjectWeights: (state: ProjectState) => {
            state.data.sr_weight = initialState.data.sr_weight;
            state.data.srs_weight = initialState.data.srs_weight;
            state.data.sfp_weight = initialState.data.sfp_weight;
            state.data.wq_weight = initialState.data.wq_weight;
            state.data.ls_weight = initialState.data.ls_weight;
            state.data.l_weight = initialState.data.l_weight;
            state.data.weight_limits_warnings = [];
        },
        clearProjectCategories: (state: ProjectState) => {
            state.data.categories = [];
        },
        startSaveProject: (state: ProjectState) => {
            state.saving = true;
            state.error = null;
        },
        completeSaveProject: (state: ProjectState, { payload }: PayloadAction<ProjectDataType>) => {
            state.data = payload;
            state.saving = false;
            state.saved = true;
            state.error = null;
        },
        failSaveProject: (state: ProjectState, { payload }: PayloadAction<string | null>) => {
            state.saving = false;
            state.error = payload;
        },
        startResetProject: (state: ProjectState) => {
            state.saving = true;
            state.error = null;
        },
        completeResetProject: (state: ProjectState, { payload }: PayloadAction<ProjectDataType>) => {
            state.data = payload;
            state.saving = false;
            state.saved = true;
            state.error = null;
        },
        failResetProject: (state: ProjectState, { payload }: PayloadAction<string | null>) => {
            state.saving = false;
            state.error = payload;
        },
        startSubmitProject: (state: ProjectState) => {
            state.submitting = true;
            state.error = null;
        },
        completeSubmitProject: (state: ProjectState, { payload }: PayloadAction<ProjectStatusType>) => {
            state.data.status = payload;
            state.submitting = false;
            state.error = null;
        },
        failSubmitProject: (state: ProjectState, { payload }: PayloadAction<string | null>) => {
            state.submitting = false;
            state.error = payload;
        },
        setProjectDistrict: (state: ProjectState, { payload }: PayloadAction<DistrictType>) => {
            state.data.district_name = payload;
        },
        setProjectWastewaterSystem: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.data.wastewater_system_name = payload;
        },
        clearProjectWastewaterSystem: (state: ProjectState) => {
            state.data.wastewater_system_name = undefined;
            state.data.wastewater_system = undefined;
        },
        setProjectState: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.data.state = payload;
        },
        setProjectTown: (state: ProjectState, { payload }: PayloadAction<string | undefined>) => {
            state.data.town_name = payload;
        },
        clearProjectTown: (state: ProjectState) => {
            state.data.town_name = undefined;
            state.data.town = undefined;
        },
        setProjectName: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.data.name = payload;
        },
        setProjectWBS: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.data.wbs_number = payload;
        },
        setProjectBaseRate: (state: ProjectState, { payload }: PayloadAction<BaseRateType>) => {
            state.data.base_rate = payload;
        },
        setProjectType: (state: ProjectState, { payload }: PayloadAction<ProjectTypeType>) => {
            state.data.project_type = payload;
        },
        setProjectAssetCategory: (state: ProjectState, { payload }: PayloadAction<AssetCategoryType>) => {
            state.data.asset_category = payload;
        },
        setCpsReferenceNumber: (state: ProjectState, { payload }: PayloadAction<string | undefined>) => {
            state.data.cps_ref_num = payload;
        },
        setProjectDescription: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.data.description = payload;
        },
        setProjectFoundationalFilingPeriod: (state: ProjectState, { payload }: PayloadAction<number | undefined>) => {
            state.data.foundational_filing_period = payload;
        },
        setProjectWeight: (
            state: ProjectState,
            { payload: { key, value, limits } }: PayloadAction<ProjectWeightPayloadType>,
        ) => {
            state.data[key as keyof ProjectWeightsType] = isNaN(value) ? 0 : value;
            state.data.weight_limits_warnings = getUniqueWeightLimitWarnings(state.data, limits);
            state.saved = false;
        },
        setProjectWeights: (
            state: ProjectState,
            { payload: { sr_weight, srs_weight, sfp_weight, wq_weight, ls_weight, l_weight } },
        ) => {
            state.data.sr_weight = sr_weight;
            state.data.srs_weight = srs_weight;
            state.data.sfp_weight = sfp_weight;
            state.data.wq_weight = wq_weight;
            state.data.ls_weight = ls_weight;
            state.data.l_weight = l_weight;
        },
        setProjectEstInService: (state: ProjectState, { payload }: PayloadAction<string | undefined>) => {
            state.data.est_in_service = payload;
        },
        setProjectScore: (state: ProjectState, { payload }: PayloadAction<number>) => {
            state.data.total_impact_score = payload;
        },
        setProjectHighCostFactors: (state: ProjectState, { payload }: PayloadAction<Array<HighCostFactorsType>>) => {
            state.data.high_cost_factors = payload;
        },
        setProjectAssetTypes: (state: ProjectState, { payload }: PayloadAction<Array<AssetTypeType>>) => {
            state.data.asset_type = payload;
        },

        setProjectCategories: (state: ProjectState, { payload }: PayloadAction<Array<investmentCategoryLabel>>) => {
            state.data.categories = payload;
        },
        setWastewaterProjectWeights: (state: ProjectState) => {
            state.data.categories = [projectWeightLabels.srs_weight as investmentCategoryLabel];
            state.data.srs_weight = 1;
        },
        setProjectCost: (
            state: ProjectState,
            { payload }: PayloadAction<{ estimate: number | undefined; preset: boolean }>,
        ) => {
            if (payload.preset) {
                state.data.estimate = undefined;
                state.data.preset = { ...state.data.preset, estimate: payload.estimate };
            } else {
                state.data.estimate = payload.estimate;
                state.data.preset = { ...state.data.preset, estimate: undefined };
            }
        },
        setProjectDuration: (
            state: ProjectState,
            { payload }: PayloadAction<{ est_duration: number | undefined; preset: boolean }>,
        ) => {
            if (payload.preset) {
                state.data.est_duration = undefined;
                state.data.preset = { ...state.data.preset, est_duration: payload.est_duration };
            } else {
                state.data.est_duration = payload.est_duration;
                state.data.preset = { ...state.data.preset, est_duration: undefined };
            }
        },
        setProjectJustification: (state: ProjectState, { payload }: PayloadAction<Array<string>>) => {
            state.data.justification = payload;
        },
        addProjectPipe: (state: ProjectState, { payload }: PayloadAction<PipeFeatureType>) => {
            state.data.pipes = [...state.data.pipes, payload.gisuid];
            state.data.total_length = getMainLength(state.data.total_length) + payload.mainlength;
            state.saved = false;
        },
        removeProjectPipe: (state: ProjectState, { payload }: PayloadAction<PipeFeatureType>) => {
            state.data.pipes = state.data.pipes.filter((pipe) => pipe != payload.gisuid);
            state.data.total_length = getMainLength(state.data.total_length) - payload.mainlength;
            state.saved = false;
        },
        startSelectProjectPipes: (state: ProjectState) => {
            state.selecting = true;
            state.error = null;
        },
        completeSelectProjectPipes: (
            state: ProjectState,
            { payload }: PayloadAction<{ pipes: string[]; total_length: number }>,
        ) => {
            state.data.pipes = payload.pipes;
            state.data.total_length = payload.total_length;
            state.selecting = false;
            state.saved = false;
        },
        failSelectProjectPipes: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.selecting = false;
            state.error = payload;
        },
        startUpdatePipeDetails: (state: ProjectState) => {
            state.updating = true;
            state.error = null;
        },
        completeUpdatePipeDetails: (
            state: ProjectState,
            { payload }: PayloadAction<{ added: PipeDetails; removed: string[] }>,
        ) => {
            const { added, removed } = payload;
            const pipe_details = state.data.pipe_details ? { ...state.data.pipe_details, ...added } : {};
            removed.forEach((gisuid) => delete pipe_details[gisuid]);

            const pipeCount = size(pipe_details);
            const rehabPipeCount = size(filter(pipe_details, { is_rehab: true }));
            // we need to compute this to keep it up to date as the pipes get updated
            state.data.rehab_pipe_pct = pipeCount > 0 ? rehabPipeCount / pipeCount : 0;
            state.data.pipe_details = pipe_details;
            state.updating = false;
        },
        failUpdatePipeDetails: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.updating = false;
            state.error = payload;
        },
        clearAllProjectPipes: (state: ProjectState) => {
            state.data.pipes = [];
            state.data.total_impact_score = 0;
            state.data.total_length = 0;
            state.data.rehab_pipe_pct = 0;
            state.data.extent = null;
            state.data.center = null;
            state.data.pipes_summary = undefined;
            state.saved = false;
        },
        clearProjectDistrict: (state: ProjectState) => {
            state.data.district = undefined;
            state.data.wastewater_system = undefined;
            state.data.district_name = '';
            state.data.wastewater_system_name = '';
        },
        startValidateProject: (state: ProjectState) => {
            state.updating = true;
            state.error = null;
        },
        completeValidateProject: (state: ProjectState, { payload }: PayloadAction<{ status: ProjectStatusType }>) => {
            state.updating = false;
            state.error = null;
            state.data.status = payload.status;
        },
        failValidateProject: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.updating = false;
            state.error = payload;
        },
        startCompleteProject: (state: ProjectState) => {
            state.updating = true;
            state.error = null;
        },
        completeCompleteProject: (state: ProjectState, { payload }: PayloadAction<ProjectDataType>) => {
            state.updating = false;
            state.error = null;
            state.data = payload;
        },
        failCompleteProject: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.updating = false;
            state.error = payload;
        },
        startRevertProject: (state: ProjectState) => {
            state.updating = true;
            state.error = null;
        },
        completeRevertProject: (state: ProjectState, { payload }: PayloadAction<ProjectDataType>) => {
            state.updating = false;
            state.error = null;
            state.data = payload;
        },
        failRevertProject: (state: ProjectState, { payload }: PayloadAction<string>) => {
            state.updating = false;
            state.error = payload;
        },

        setProjectPage: (state: ProjectState, { payload }: PayloadAction<number>) => {
            state.page = payload;
        },
        incrementProjectPage: (state: ProjectState) => {
            state.page += 1;
        },
        decrementProjectPage: (state: ProjectState) => {
            state.page -= 1;
        },
    },
    extraReducers: clearOnLogout,
});

export const {
    clearProject,
    clearProjectWeights,
    setProjectDistrict,
    setProjectWastewaterSystem,
    clearProjectWastewaterSystem,
    setProjectHighCostFactors,
    setProjectAssetTypes,
    setProjectBaseRate,
    setProjectName,
    setProjectDescription,
    setProjectFoundationalFilingPeriod,
    setProjectType,
    setProjectAssetCategory,
    setCpsReferenceNumber,
    setProjectWeight,
    setProjectWeights,
    setProjectEstInService,
    setProjectScore,
    setProjectCategories,
    setWastewaterProjectWeights,
    clearProjectCategories,
    setProjectCost,
    setProjectDuration,
    setProjectJustification,
    setProjectState,
    setProjectTown,
    clearProjectTown,
    setProjectWBS,
    addProjectPipe,
    removeProjectPipe,
    startSelectProjectPipes,
    completeSelectProjectPipes,
    failSelectProjectPipes,
    startFetchProject,
    completeFetchProject,
    failFetchProject,
    startSaveProject,
    completeSaveProject,
    failSaveProject,
    startResetProject,
    completeResetProject,
    failResetProject,
    startSubmitProject,
    completeSubmitProject,
    failSubmitProject,
    startUpdatePipeDetails,
    completeUpdatePipeDetails,
    failUpdatePipeDetails,
    clearAllProjectPipes,
    clearProjectDistrict,
    startValidateProject,
    completeValidateProject,
    failValidateProject,
    startCompleteProject,
    completeCompleteProject,
    failCompleteProject,
    startRevertProject,
    completeRevertProject,
    failRevertProject,
    setProjectPage,
    incrementProjectPage,
    decrementProjectPage,
} = projectSlice.actions;

const parseWeight = (weight: string | number | null): number => {
    if (!weight) {
        return 0.0;
    }
    if (typeof weight == 'string') {
        return parseFloat(weight);
    }
    return weight;
};

const formatProject = (data: ProjectDataType) => {
    const { sr_weight, srs_weight, sfp_weight, wq_weight, ls_weight, l_weight } = data;
    const pipeDetails: PipeDetails = data.pipe_details ? data.pipe_details : {};
    return {
        ...data,
        pipe_details: pipeDetails,
        sr_weight: parseWeight(sr_weight),
        srs_weight: parseWeight(srs_weight),
        sfp_weight: parseWeight(sfp_weight),
        wq_weight: parseWeight(wq_weight),
        ls_weight: parseWeight(ls_weight),
        l_weight: parseWeight(l_weight),
    };
};

export const saveProject =
    (project: ProjectDataType, onSaveSuccess?: (project: ProjectDataType) => void): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        const {
            auth,
            projects: { limits },
        } = getState();
        const userCanSave = userCanSaveProject(auth.user, project);
        if (!userCanSave) return;

        dispatch(startSaveProject());
        try {
            const data = project.universal_id
                ? await api.putProject(project.universal_id, project)
                : await api.postNewProject(project);

            // Saving a project does not return the pipe details so add them
            // back in to the project that gets stashed in redux
            dispatch(
                completeSaveProject(
                    formatProject({
                        ...data,
                        ...{ pipe_details: project.pipe_details },
                        ...{ weight_limits_warnings: getUniqueWeightLimitWarnings(project, limits) },
                    }),
                ),
            );
            if (onSaveSuccess && data.universal_id) {
                onSaveSuccess(data);
            }
        } catch (e: unknown) {
            if (handleFieldErrors(e)) {
                dispatch(failSaveProject(null));
            } else {
                const message: string = getErrorMessage(e, 'Save error.');
                dispatch(failSaveProject(message));
            }
        }
    };

export const resetProject =
    (projectId: string, onResetSuccess?: () => void): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startResetProject());
        try {
            const data = await api.putResetProject(projectId);
            // Saving a project does not return the pipe details
            // in this case the pipe details will always be empty
            dispatch(
                completeResetProject(
                    formatProject({
                        ...data,
                        ...{ pipe_details: {} },
                        ...{ weight_limits_warnings: [] },
                    }),
                ),
            );
            if (onResetSuccess) {
                onResetSuccess();
            }
        } catch (e: unknown) {
            if (handleFieldErrors(e)) {
                dispatch(failResetProject(null));
            } else {
                const message: string = getErrorMessage(e, 'Reset error.');
                dispatch(failResetProject(message));
            }
        }
    };

// only used as a callback from saveProject
const _submitProject =
    (projectId: string | number | null): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startSubmitProject());
        if (projectId) {
            try {
                const data = await api.putSubmitProject(projectId.toString());
                dispatch(completeSubmitProject(data.status));
            } catch (e: unknown) {
                const message: string = getErrorMessage(e, 'Submit error.');
                dispatch(failSubmitProject(message));
            }
        }
    };

export const submitProject =
    (project: ProjectDataType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startSubmitProject());
        dispatch(saveProject(project, () => dispatch(_submitProject(project.universal_id))));
    };

export const updatePipeDetails =
    (added: string[], removed: string[], project: ProjectDataType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startUpdatePipeDetails());
        try {
            const new_pipe_details = await api.getPipesDetails(added, project);
            dispatch(completeUpdatePipeDetails({ added: new_pipe_details, removed: removed }));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Error updating pipe details.');
            dispatch(failUpdatePipeDetails(message));
        }
    };

export const addOrRemoveManyPipes =
    (id: string, project: ProjectDataType, bbox: number[]) =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startSelectProjectPipes());

        try {
            const data = await api.selectPipes(project, bbox);
            dispatch(completeSelectProjectPipes(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Pipe selection error.');

            dispatch(failSelectProjectPipes(message));
        }
    };

export const validateProject =
    ({ id, status, notes }: { id?: string; status: 'approve' | 'deny'; notes?: string }): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        const { auth } = getState();
        const userCanValidate = userIsValidator(auth.user);
        if (!userCanValidate) return;

        if (!id) return;

        dispatch(startValidateProject());
        try {
            let data: { status: ProjectStatusType } = { status: 'PENDING' };
            if (status === 'approve') {
                data = await api.approveProject(id, notes ? notes : '');
            } else if (status === 'deny') {
                data = await api.denyProject(id, notes ? notes : '');
            }

            dispatch(completeValidateProject(data));
            dispatch(fetchFilteredProjects());
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Save error.');
            dispatch(failValidateProject(message));
        }
    };

export const completeProject =
    ({ project, date }: { project: ProjectDataType; date: string }): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        const { auth } = getState();
        const userCanSave = userCanSaveProject(auth.user, project);
        if (!userCanSave) return;

        if (!project.universal_id) return;
        dispatch(startCompleteProject());
        try {
            const data = await api.completeProject(project.universal_id, date);
            dispatch(completeCompleteProject(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Save error.');
            dispatch(failCompleteProject(message));
        }
    };

export const revertCompleteProject =
    ({ project }: { project: ProjectDataType }): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        const { auth } = getState();
        const userCanRevert = userCanRevertCompletedProject(auth.user, project);
        if (!userCanRevert) return;

        dispatch(startRevertProject());
        try {
            const data = await api.revertProject(project.universal_id);
            dispatch(completeRevertProject(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Save error.');
            dispatch(failRevertProject(message));
        }
    };

export const selectProjectData = ({ currentProject }: RootState) => {
    const project = currentProject.data;
    if (!project.pipe_details) {
        return { ...project, pipe_details: {} };
    }
    return project;
};
export const selectProjectDistrict = ({ currentProject }: RootState) => currentProject.data.district_name;
export const selectProjectWastewaterSystem = ({ currentProject }: RootState) =>
    currentProject.data.wastewater_system_name;
export const selectProjectId = ({ currentProject }: RootState) => currentProject.data.universal_id;
export const selectProjectSaving = ({ currentProject }: RootState) => currentProject.saving;
export const selectProjectSaved = ({ currentProject }: RootState) => currentProject.saved;
export const selectProjectSelecting = ({ currentProject }: RootState) => currentProject.selecting;
export const selectProjectSubmitting = ({ currentProject }: RootState) => currentProject.submitting;
export const selectProjectLoading = ({ currentProject }: RootState) => currentProject.loading;
export const selectProjectError = ({ currentProject }: RootState) => currentProject.error;
export const selectProjectPipes = ({ currentProject }: RootState) => currentProject.data.pipes;
export const selectProjectScore = ({ currentProject }: RootState) => currentProject.data.total_impact_score;
export const selectProjectCost = ({ currentProject }: RootState) =>
    currentProject.data.estimate || currentProject.data.preset?.estimate;
export const selectProjectCps = ({ currentProject }: RootState) => currentProject.data.cps_ref_num;
export const selectProjectCostLabel = ({ currentProject }: RootState) => projectCostLabel(currentProject.data);
export const selectProjectEstInService = ({ currentProject }: RootState) => currentProject.data.est_in_service;
export const selectIsProjectWastewater = ({ currentProject }: RootState) =>
    currentProject.data.asset_category === 'Wastewater';
export const selectProjectLength = ({ currentProject }: RootState) => currentProject.data.total_length;
export const selectProjectPage = ({ currentProject }: RootState) => currentProject.page;
export const selectProjectPipeVersion = ({ currentProject }: RootState) => currentProject.data.pipe_version;

export const selectWeightLimitsWarnings = ({ currentProject }: RootState) => currentProject.data.weight_limits_warnings;

export const selectProjectPipeDetails = ({ currentProject }: RootState) => {
    const pipeDetails = currentProject.data.pipe_details;
    if (!pipeDetails) {
        return {};
    }
    return pipeDetails;
};
export const selectProjectUpdating = ({ currentProject }: RootState) => currentProject.updating;

export const selectProjectWeights = ({ currentProject }: RootState): ProjectWeightsType => {
    const { sr_weight, srs_weight, sfp_weight, wq_weight, ls_weight, l_weight } = currentProject.data;
    return { sr_weight, srs_weight, sfp_weight, wq_weight, ls_weight, l_weight };
};

export const selectSelectedWeights = ({ currentProject }: RootState): SelectedWeightsType => {
    const { categories } = currentProject.data;
    const selectedWeights = [...new Set(categories.map((cat) => investmentCategories[cat]))];

    return pick(currentProject.data, selectedWeights);
};

export const selectProjectRehabPct = ({ currentProject }: RootState): number => {
    return currentProject.data.rehab_pipe_pct;
};

export default projectSlice.reducer;

export const mapStateToProjectProps = (state: RootState) => {
    return {
        loading: state.currentProject.loading,
        error: state.currentProject.error,
    };
};
