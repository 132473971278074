import { Text, Link, Box, HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAppSelector } from '../util/hooks';
import { selectUnreadNotificationsCount } from '../reducers/notifications';

interface HeaderLinkType {
    text: string | ReactNode;
    shouldDisplay: boolean | null;
    onClick?: () => void;
    to: string;
    isExternal?: boolean;
    underlineIt?: boolean;
}

interface CircleWithNumberProps {
    n: number;
}

const CircleWithNumber = ({ n }: CircleWithNumberProps) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="18px"
            height="18px"
            borderRadius="50%"
            backgroundColor="red.500"
            borderColor="white"
            borderWidth="0.5px"
            color="white"
            fontWeight="bold"
            fontSize="12px"
        >
            {n}
        </Box>
    );
};

const HeaderLink = ({ text, onClick, shouldDisplay, to, isExternal, underlineIt }: HeaderLinkType) => {
    const location = useLocation();
    const unreadNotificationsCount = useAppSelector(selectUnreadNotificationsCount);
    const isCurrentPath = location?.pathname === to && underlineIt;

    return shouldDisplay ? (
        <Link
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
            color="white"
            fontSize="20px"
            fontWeight={isCurrentPath ? 'bold' : 'normal'}
            ml="24px"
            as={isExternal ? undefined : RouterLink}
            to={to}
            href={to}
            isExternal={isExternal}
            _hover={{ textDecoration: 'none' }}
        >
            <HStack alignItems={'center'} justifyContent={'center'}>
                {text === 'Notifications' && unreadNotificationsCount > 0 && (
                    <CircleWithNumber n={unreadNotificationsCount}></CircleWithNumber>
                )}
                <Text _hover={{ textDecoration: 'underline' }} textDecoration={isCurrentPath ? 'underline' : ''}>
                    {text}
                </Text>
            </HStack>
        </Link>
    ) : null;
};

export default HeaderLink;
