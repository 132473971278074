import { ThunkDispatch } from 'redux-thunk';

import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from '../api';
import { DistrictType } from '../types/reducers/districts';
import { TownsDataType, TownType, TownsState } from '../types/reducers/towns';
import { OptionType, ThunkType } from '../types/types';
import { getErrorMessage } from '../util/utils';

import type { RootState } from '../store';
import { createClearOnLogout } from './auth';

const initialState: TownsState = {
    data: [],
    loading: false,
    error: null,
};

const clearOnLogout = createClearOnLogout<TownsState>(initialState);

export const townsSlice = createSlice({
    name: 'towns',
    initialState,
    reducers: {
        startFetchTowns: () => ({
            data: [],
            loading: true,
            error: null,
        }),
        completeFetchTowns: (state: TownsState, { payload }: PayloadAction<TownsDataType>) => {
            state.data = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchTowns: (state: TownsState, { payload }: PayloadAction<string>) => {
            state.data = [];
            state.loading = false;
            state.error = payload;
        },
    },
    extraReducers: clearOnLogout,
});

export const {
    startFetchTowns: startFetchTowns,
    completeFetchTowns: completeFetchTowns,
    failFetchTowns: failFetchTowns,
} = townsSlice.actions;

export const fetchTowns =
    (district: DistrictType, wastewater_system?: string): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchTowns());
        try {
            const data = await api.getTowns({ state: 'NJ', district, wastewater_system });
            dispatch(completeFetchTowns(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Failed to fetch towns.');

            dispatch(failFetchTowns(message));
        }
    };

export const selectTowns = ({ towns: towns }: RootState) => towns;
export const selectTownsLoading = ({ towns }: RootState) => towns.loading;
export const selectTownsError = ({ towns }: RootState) => towns.error;

export const selectTownsOptions = ({ towns }: RootState) =>
    towns.data.map((t: TownType): OptionType => ({ label: t, value: t }));

export default townsSlice.reducer;
