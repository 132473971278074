import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';

import * as api from '../api';
import { RootState } from '../store';
import { getErrorMessage } from '../util/utils';
import { ThunkType } from '../types/types';
import { ProjectMostDataType } from '../types/reducers/projects';
import { Paginator } from '../types/api';
import { createClearOnLogout } from './auth';
import { selectFilteredProjectsQuery } from './projects';

interface ProjectsState {
    data: ProjectMostDataType[];
    loading: boolean;
    error: string | null;
    paginator: Paginator | null;
}

const initialState: ProjectsState = {
    data: [],
    loading: false,
    error: null,
    paginator: null,
};

const clearOnLogout = createClearOnLogout<ProjectsState>(initialState);

export const filteredPaginatedProjectsSlice = createSlice({
    name: 'filteredPaginatedProjects',
    initialState,
    reducers: {
        startFetchProjects: (state: ProjectsState) => {
            state.loading = true;
            state.error = null;
        },
        setProjects: (state: ProjectsState, { payload }) => {
            state.data = payload.projects;
            state.paginator = payload.paginator;
        },
        updateProjects: (state: ProjectsState, { payload }) => {
            state.data = [...state.data, ...payload.projects];
            state.paginator = payload.paginator;
        },
        completeFetchProjects: (state: ProjectsState) => {
            state.loading = false;
            state.error = null;
        },
        failFetchProjects: (state: ProjectsState, { payload }) => {
            state.data = [];
            state.loading = false;
            state.error = payload;
        },
    },
    extraReducers: clearOnLogout,
});

const { startFetchProjects, setProjects, updateProjects, completeFetchProjects, failFetchProjects } =
    filteredPaginatedProjectsSlice.actions;

export const fetchFilteredPaginatedProjects =
    (page_size?: number, nextPage?: boolean): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        const state = getState();
        let page = 1;
        if (nextPage) {
            const next = state.filteredPaginatedProjects.paginator?.next_page_number;
            if (!next) {
                return null;
            }
            page = next;
        }
        dispatch(startFetchProjects());
        try {
            const payload = await api.getProjects(selectFilteredProjectsQuery(state, 'most', page_size, page));
            if (nextPage) {
                dispatch(updateProjects(payload));
            } else {
                dispatch(setProjects(payload));
            }
            dispatch(completeFetchProjects());
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Fetching projects error.');

            dispatch(failFetchProjects(message));
        }
    };

export const selectFilteredPaginatedProjectsData = (state: RootState) => state.filteredPaginatedProjects.data;
export const selectFilteredPaginatedProjectsLoading = (state: RootState) => state.filteredPaginatedProjects.loading;
export default filteredPaginatedProjectsSlice.reducer;
