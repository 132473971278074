import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';

import * as api from '../api';
import { RootState } from '../store';
import { getErrorMessage, projectToCsvRecord } from '../util/utils';
import { ThunkType } from '../types/types';
import { Paginator } from '../types/api';
import { createClearOnLogout } from './auth';
import { selectFilteredProjectsQuery } from './projects';
import { ProjectCsvDataType, ProjectCsvResponseType } from '../types/reducers/projects';
import { PROJECT_DOWNLOAD_PAGE_SIZE } from '../util/constants';
import { map, range } from 'lodash';

interface DownloadState {
    data: ProjectCsvDataType[];
    loading: boolean;
    error: string | null;
    paginator: Paginator | null;
}

const initialState: DownloadState = {
    data: [],
    loading: false,
    error: null,
    paginator: null,
};

const clearOnLogout = createClearOnLogout<DownloadState>(initialState);

export const downloadSlice = createSlice({
    name: 'download',
    initialState,
    reducers: {
        startFetchPaginator: (state: DownloadState) => {
            state.loading = true;
            state.error = null;
        },
        completeFetchPaginator: (state: DownloadState, { payload }) => {
            state.paginator = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchPaginator: (state: DownloadState, { payload }) => {
            state.paginator = null;
            state.loading = false;
            state.error = payload;
        },
        startDownload: (state: DownloadState) => {
            state.data = [];
            state.loading = true;
            state.error = null;
        },
        completeDownload: (state: DownloadState, { payload }) => {
            state.data = payload;
            state.loading = false;
            state.error = null;
        },
        failDownload: (state: DownloadState, { payload }) => {
            state.data = [];
            state.loading = false;
            state.error = payload;
        },
        clearDownload: (state: DownloadState) => {
            state.data = [];
            state.loading = false;
        },
    },
    extraReducers: clearOnLogout,
});

export const {
    startFetchPaginator,
    completeFetchPaginator,
    failFetchPaginator,
    startDownload,
    completeDownload,
    failDownload,
    clearDownload,
} = downloadSlice.actions;

export const fetchDownloadPaginator =
    (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        const state = getState();
        dispatch(startFetchPaginator());
        try {
            const payload = await api.getPaginatorData(
                selectFilteredProjectsQuery(state, 'most', PROJECT_DOWNLOAD_PAGE_SIZE),
            );
            dispatch(completeFetchPaginator(payload));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Error fetching pagination data.');
            dispatch(failFetchPaginator(message));
        }
    };

export const fetchDownloadProjects =
    (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        const state = getState();
        const { limits } = state.projects;
        const paginator = state.download.paginator;
        dispatch(startDownload());
        if (paginator) {
            try {
                const promises = map(range(1, paginator.num_pages + 1), (i) =>
                    api.getCsvRecords(selectFilteredProjectsQuery(state, 'most', PROJECT_DOWNLOAD_PAGE_SIZE, i)),
                );
                const data = await Promise.all(promises);
                const csvRows = data.reduce(
                    (acc: ProjectCsvDataType[], projects: ProjectCsvResponseType[]): ProjectCsvDataType[] => {
                        return acc.concat(projects.map((project) => projectToCsvRecord(project, limits)));
                    },
                    [],
                );
                dispatch(completeDownload(csvRows));
            } catch (e: unknown) {
                const message: string = getErrorMessage(e, 'Error downloading projects CSV');
                dispatch(failDownload(message));
            }
        } else {
            dispatch(failDownload('No paginator is defined'));
        }
    };

export const selectDownloadData = (state: RootState) => state.download.data;
export const selectDownloadLoading = (state: RootState) => state.download.loading;
export default downloadSlice.reducer;
