import { IconButton, Link, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FEEDBACK_FORM_URL, Paths } from '../util/constants';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import { logoutUser, selectUser } from '../reducers/auth';
import { FaUserCircle } from 'react-icons/fa';
import { userIsStaff } from '../util/utils';

const UserMenu = () => {
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();

    return (
        <Menu>
            <MenuButton
                fontSize="24px"
                color="white"
                as={IconButton}
                aria-label="Options"
                ml="16px"
                icon={<FaUserCircle />}
                variant="outline"
                backgroundColor="transparent"
                borderColor="transparent"
                _hover={{
                    backgroundColor: 'transparent',
                    borderColor: 'white',
                }}
                _focus={{
                    backgroundColor: 'transparent',
                    borderColor: 'white',
                    boxShadow: 'none',
                }}
                _active={{
                    backgroundColor: 'transparent',
                    borderColor: 'white',
                }}
            />
            <MenuList color="black" boxShadow="md" zIndex="10000">
                {user && (
                    <>
                        <MenuItem>{user.email}</MenuItem>
                        <MenuDivider />
                    </>
                )}
                {userIsStaff(user) && (
                    <MenuItem as={Link} href={`${window.location.origin}${Paths.djangoAdmin}`} isExternal>
                        Settings
                    </MenuItem>
                )}
                <MenuItem as={Link} href={FEEDBACK_FORM_URL} isExternal>
                    Submit feedback
                </MenuItem>
                <MenuItem as={RouterLink} to={Paths.home} onClick={user ? () => dispatch(logoutUser()) : undefined}>
                    {user ? 'Sign out' : 'Sign in'}
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default UserMenu;
