import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';

import * as api from '../api';
import { RootState } from '../store';
import { getErrorMessage } from '../util/utils';
import { ThunkType } from '../types/types';
import { ProjectSomeDataType } from '../types/reducers/projects';
import { createClearOnLogout } from './auth';
import { setClusterMapViewport } from './clusterMap';
import { selectFilteredProjectsQuery } from './projects';

interface ProjectsState {
    data: ProjectSomeDataType[];
    loading: boolean;
    error: string | null;
}

const initialState: ProjectsState = {
    data: [],
    loading: false,
    error: null,
};

const clearOnLogout = createClearOnLogout<ProjectsState>(initialState);

export const filteredProjectsSlice = createSlice({
    name: 'filteredProjects',
    initialState,
    reducers: {
        startFetchProjects: (state: ProjectsState) => {
            state.loading = true;
            state.error = null;
        },
        completeFetchProjects: (state: ProjectsState, { payload }) => {
            state.data = payload.projects;
            state.loading = false;
            state.error = null;
        },
        failFetchProjects: (state: ProjectsState, { payload }) => {
            state.data = [];
            state.loading = false;
            state.error = payload;
        },
    },
    extraReducers: clearOnLogout,
});

export const { startFetchProjects, completeFetchProjects, failFetchProjects } = filteredProjectsSlice.actions;

export const fetchFilteredProjects =
    (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        const state = getState();
        if (state.projects.loading) return null;
        dispatch(startFetchProjects());
        try {
            const payload = await api.getProjects(selectFilteredProjectsQuery(state, 'some'));
            dispatch(completeFetchProjects(payload));
            if (payload.extent) {
                dispatch(setClusterMapViewport(payload.extent));
            }
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Fetching projects error.');

            dispatch(failFetchProjects(message));
        }
    };

export const selectFilteredProjectsData = (state: RootState) => state.filteredProjects.data;
export const selectFilteredProjectsCount = (state: RootState) => state.filteredProjects.data.length;
export const selectFilteredProjectsLoading = (state: RootState) => state.filteredProjects.loading;
export default filteredProjectsSlice.reducer;
