import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { createClearOnLogout } from './auth';

interface PollingState {
    tick: number;
    interval: number;
    active: boolean;
}

const initialState: PollingState = {
    active: false,
    tick: 0,
    interval: 30000,
};

const clearOnLogout = createClearOnLogout<PollingState>(initialState);

export const pollingSlice = createSlice({
    name: 'polling',
    initialState,
    reducers: {
        startPolling: (state: PollingState) => {
            state.active = true;
        },
        setTick: (state: PollingState) => {
            state.tick = state.tick + 1;
        },
        stopPolling: (state: PollingState) => {
            state.active = false;
        },
    },
    extraReducers: clearOnLogout,
});

export const { setTick, startPolling, stopPolling } = pollingSlice.actions;

export const selectPollingActive = (state: RootState) => state.polling.active;
export const selectPollingTick = (state: RootState) => state.polling.tick;
export const selectPollingInterval = (state: RootState) => state.polling.interval;

export default pollingSlice.reducer;
