import { ThunkDispatch } from 'redux-thunk';

import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from '../api';
import { ThunkType } from '../types/types';
import { getErrorMessage } from '../util/utils';

import type { RootState } from '../store';
import { createClearOnLogout } from './auth';
import { DashboardDataType, DashboardState, SummaryStatsType } from '../types/reducers/dashboard';
import { selectFilteredProjectsQuery } from './projects';

const defaultSummaryStats: SummaryStatsType = {
    count: null,
    average_score: null,
    total_cost: null,
    average_cost: null,
    total_length: null,
    percent_approved: null,
    completion_date_diff: null,
    average_cost_per_ft: null,
    by_status: {
        submitted: { count: null, total_cost: null },
        completed: { count: null, total_cost: null },
        proposed: { count: null, total_cost: null },
        cancelled: { count: null, total_cost: null },
    },
};

const defaultDashboardData = {
    all_projects_stats: defaultSummaryStats,
    user_projects_stats: defaultSummaryStats,
};

const initialState: DashboardState = {
    data: defaultDashboardData,
    loading: false,
    error: null,
};

const clearOnLogout = createClearOnLogout<DashboardState>(initialState);

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        startFetchDashboard: () => ({
            data: defaultDashboardData,
            loading: true,
            error: null,
        }),
        completeFetchDashboard: (state: DashboardState, { payload }: PayloadAction<DashboardDataType>) => {
            state.data = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchDashboard: (state: DashboardState, { payload }: PayloadAction<string>) => {
            state.data = defaultDashboardData;
            state.loading = false;
            state.error = payload;
        },
    },
    extraReducers: clearOnLogout,
});

export const { startFetchDashboard, completeFetchDashboard, failFetchDashboard } = dashboardSlice.actions;

export const fetchDashboard =
    (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        dispatch(startFetchDashboard());
        try {
            const state = getState();
            const data = await api.getDashboardHeader(selectFilteredProjectsQuery(state));
            dispatch(completeFetchDashboard(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Failed to fetch dashboard.');

            dispatch(failFetchDashboard(message));
        }
    };

export const selectDashboard = ({ dashboard: dashboard }: RootState) => dashboard;

export const selectAllProjectsSummaryStats = (state: RootState) => selectDashboard(state).data.all_projects_stats;
export const selectUserProjectsSummaryStats = (state: RootState) => selectDashboard(state).data.user_projects_stats;

export const selectDashboardLoading = ({ dashboard }: RootState) => dashboard.loading;
export const selectDashboardError = ({ dashboard }: RootState) => dashboard.error;

export default dashboardSlice.reducer;
