import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface nextLocationState {
    value: string | null;
}

const initialState: nextLocationState = {
    value: null,
};

export const nextLocationSlice = createSlice({
    name: 'nextLocation',
    initialState,
    reducers: {
        setNextLocation: (state: nextLocationState, { payload }) => {
            state.value = payload.origin;
        },
        clearNextLocation: (state: nextLocationState) => {
            state.value = initialState.value;
        },
    },
});

export const { clearNextLocation, setNextLocation } = nextLocationSlice.actions;

export const selectNextLocation = (state: RootState) => state.nextLocation.value;
export default nextLocationSlice.reducer;
