import { AxiosError } from 'axios';
import { Feature, MultiLineString } from 'geojson';
import { ThunkDispatch } from 'redux-thunk';

import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from '../api';
import { RootState } from '../store';
import { DistrictType } from '../types/reducers/districts';
import {
    MapDataState,
    MapCoordinates,
    PipeFeatureType,
    ScoresDataType,
    ScoresType,
    MapFetchDataType,
    ProjectFetchDataType,
    PipeResponseType,
    WastewaterPipeFeature,
} from '../types/reducers/mapData';
import { ProjectDataType, ProjectStatusType, SelectedWeightsType } from '../types/reducers/projects';
import { ThunkType } from '../types/types';
import { initialViewport, mapHeight, mapWidth, PROJECT_STATUS } from '../util/constants';
import { getErrorMessage, getProjectWeights, getUniqueWeightLimitWarnings } from '../util/utils';
import createViewPortFromBounds from '../util/createViewPortFromBounds';
import { startFetchProject, completeFetchProject, failFetchProject } from './project';
import { createClearOnLogout } from './auth';

const initialState: MapDataState = {
    data: {
        impactScores: {},
        features: [],
    },
    viewport: initialViewport,
    pin: undefined,
    loading: false,
    loadingStartTime: null,
    error: '',
    scored: true,
    extent: undefined,
};

const clearOnLogout = createClearOnLogout<MapDataState>(initialState);

export const mapDataSlice = createSlice({
    name: 'mapData',
    initialState,
    reducers: {
        setViewport: (state: MapDataState, { payload }) => {
            state.viewport = payload;
        },
        setMapExtent: (state: MapDataState, { payload }) => {
            state.extent = payload;
        },
        clearMapData: () => initialState,
        startFetchMapData: (state: MapDataState) => {
            state.data = initialState.data;
            state.loading = true;
            state.scored = false;
            state.error = '';
        },
        completeFetchMapData: (state: MapDataState, { payload }: PayloadAction<MapDataState>) => {
            const { data, viewport } = payload;
            state.data = data;
            state.scored = true;
            state.viewport = viewport;
            state.loading = false;
        },
        failFetchMapData: (state: MapDataState, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        startFetchImpactScores: (state: MapDataState) => {
            state.loading = true;
            state.scored = false;
            state.data.impactScores = initialState.data.impactScores;
            state.error = '';
            state.loadingStartTime = new Date().getTime();
        },
        completeFetchImpactScores: (state: MapDataState, { payload }: PayloadAction<ScoresDataType>) => {
            state.loading = false;
            state.scored = true;
            state.data.impactScores = payload.impact_scores;
            state.loadingStartTime = null;
        },
        failFetchImpactScores: (state: MapDataState, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
            state.loadingStartTime = null;
        },
        startSetMapSearchPoint: (state: MapDataState) => {
            state.loading = true;
            state.error = '';
        },
        completeSetMapSearchPoint: (state: MapDataState, { payload }: PayloadAction<MapCoordinates>) => {
            const { latitude, longitude, extent } = payload;
            state.viewport.latitude = latitude;
            state.viewport.longitude = longitude;
            state.extent = extent;
            state.pin = [longitude, latitude];
            state.loading = false;
        },
        failSetMapSearchPoint: (state: MapDataState, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setMapDataUnscored: (state: MapDataState) => {
            state.scored = false;
        },
        clearMapSearchPoint: (state: MapDataState) => {
            state.pin = undefined;
        },
    },
    extraReducers: clearOnLogout,
});

export const {
    setViewport,
    setMapExtent,
    clearMapData,
    startFetchMapData,
    completeFetchMapData,
    failFetchMapData,
    startFetchImpactScores,
    completeFetchImpactScores,
    failFetchImpactScores,
    startSetMapSearchPoint,
    completeSetMapSearchPoint,
    failSetMapSearchPoint,
    setMapDataUnscored,
    clearMapSearchPoint,
} = mapDataSlice.actions;

export const setMapViewport =
    (extent: [number, number, number, number]): ThunkType =>
    (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        const viewport = createViewPortFromBounds({
            bounds: extent,
            width: mapWidth,
            height: mapHeight,
        });

        dispatch(setViewport(viewport));
    };

export const fetchMapData =
    (project: ProjectDataType, { district_name, projectUniversalId, weights, extent }: MapFetchDataType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchMapData());
        try {
            let pipesData: PipeResponseType;
            let impactScores: ScoresType;

            if (project.asset_category == 'Water') {
                let scoresData: ScoresDataType;
                [pipesData, scoresData] = await Promise.all([
                    api.getWaterPipesData({ district: district_name, projectUniversalId }),
                    api.getWaterPipesScoresData({ district: district_name, projectUniversalId }, weights),
                ]);
                impactScores = scoresData.impact_scores;
            } else {
                pipesData = await api.getWastewaterPipesData({
                    wastewater_system: project.wastewater_system_name,
                    district: project.district_name,
                    projectUniversalId,
                });
                impactScores = pipesData.features.reduce((acc: ScoresType, f: WastewaterPipeFeature) => {
                    if (f.score) {
                        acc[f.gisuid] = f.score;
                    }
                    return acc;
                }, {});
            }

            // Using a for loop here to handle pipes which have undefined coordinates
            const features: Feature[] = [];
            pipesData.features.forEach((f: PipeFeatureType) => {
                const { geojson, ...properties } = f;
                const json: MultiLineString = JSON.parse(geojson);
                if (json.coordinates[0]) {
                    const feature: Feature = {
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: json.coordinates[0],
                        },
                        properties,
                    };

                    features.push(feature);
                }
            });

            const bounds = extent ?? pipesData.extent;
            const viewport = bounds
                ? createViewPortFromBounds({ bounds, width: mapWidth, height: mapHeight })
                : initialViewport;

            const result: MapDataState = {
                data: {
                    features: features,
                    impactScores: impactScores,
                },
                viewport,
                // these 3 are not used when calling `completeFetchMapData`
                // but are defined in order to fulfill the type requirements
                // of `MapDataState`
                scored: true,
                loading: true,
                error: '',
                loadingStartTime: null,
            };

            dispatch(completeFetchMapData(result));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Error loading map data.');

            dispatch(failFetchMapData(message));
        }
    };

export const fetchProjectData =
    ({ universalId, userId, includeMap, isEditPage }: ProjectFetchDataType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        dispatch(startFetchProject());
        try {
            const {
                projects: { limits },
            } = getState();
            const project = await api.getProject(universalId);
            const pipe_details = await api.getPipesDetails(project.pipes, project);

            if (includeMap) {
                const { district_name, extent } = project;
                const weights = getProjectWeights(project);
                dispatch(
                    fetchMapData(project, { district_name, projectUniversalId: project.universal_id, weights, extent }),
                );
            }

            if (isEditPage) {
                // You cannot edit a project if you did not create it
                const projectUserId = project.created_by_id ? `${project.created_by_id}` : null;
                if (projectUserId !== userId) {
                    dispatch(failFetchProject('You are not authorized to edit this project'));
                    return;
                }

                // You cannot edit a project if is complete
                if (project.status === PROJECT_STATUS.COMPLETE) {
                    dispatch(failFetchProject('You cannot edit a completed project'));
                    return;
                }
                project.status = PROJECT_STATUS.DRAFT as ProjectStatusType;
            }

            dispatch(
                completeFetchProject({
                    ...project,
                    ...{ pipe_details: pipe_details },
                    ...{ weight_limits_warnings: getUniqueWeightLimitWarnings(project, limits) },
                }),
            );
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Failed to fetch project data.');

            dispatch(failFetchProject(message));
        }
    };

export const setMapSearchPoint =
    (payload: { value: string; label: string }): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startSetMapSearchPoint());
        try {
            const { value, label } = payload;
            const searchPoint = await api.getGeocodeResponse(label, value);
            const { extent } = searchPoint;

            dispatch(completeSetMapSearchPoint(searchPoint));
            dispatch(setMapViewport(extent));
        } catch (e: unknown) {
            const message: string =
                e instanceof AxiosError ? e.response?.data?.detail : 'Error setting map search point';

            dispatch(failSetMapSearchPoint(message));
        }
    };

export const fetchImpactScores =
    (district: DistrictType, weights: SelectedWeightsType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchImpactScores());
        try {
            const data = await api.getWaterPipesScoresData({ district }, weights);

            dispatch(completeFetchImpactScores(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Error loading map data.');
            dispatch(failFetchImpactScores(message));
        }
    };

export const selectImpactScores = ({ mapData }: RootState) => mapData.data.impactScores;
export const selectMapDataLoading = ({ mapData }: RootState) => mapData.loading;
export const selectMapDataScored = ({ mapData }: RootState) => mapData.scored;
export const selectMapDataLoadingStartTime = ({ mapData }: RootState) => mapData.loadingStartTime;
export const selectFeaturesCount = ({ mapData }: RootState) => mapData.data.features.length;
export default mapDataSlice.reducer;
