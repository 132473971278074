import { WastewaterSystemOptionType } from './../types/types';
import { ThunkDispatch } from 'redux-thunk';

import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from '../api';
import {
    DistrictsState,
    DistrictWithPipesCountType,
    WastewaterSystemWithPipesCountType,
} from '../types/reducers/districts';
import { OptionType, ThunkType } from '../types/types';
import { getErrorMessage } from '../util/utils';

import type { RootState } from '../store';
import { createClearOnLogout } from './auth';

const initialState: DistrictsState = {
    districts: [],
    wastewater_systems: [],
    loading: false,
    error: null,
};

const clearOnLogout = createClearOnLogout<DistrictsState>(initialState);

export const districtsSlice = createSlice({
    name: 'districts',
    initialState,
    reducers: {
        startFetchDistricts: (state: DistrictsState) => {
            state.districts = [];
            state.loading = true;
            state.error = null;
        },
        completeFetchDistricts: (state: DistrictsState, { payload }: PayloadAction<DistrictWithPipesCountType[]>) => {
            state.districts = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchDistricts: (state: DistrictsState, { payload }: PayloadAction<string>) => {
            state.districts = [];
            state.loading = false;
            state.error = payload;
        },
        startFetchWastewaterSystems: (state: DistrictsState) => {
            state.wastewater_systems = [];
            state.loading = true;
            state.error = null;
        },
        completeFetchWastewaterSystems: (
            state: DistrictsState,
            { payload }: PayloadAction<WastewaterSystemWithPipesCountType[]>,
        ) => {
            state.wastewater_systems = payload;
            state.loading = false;
            state.error = null;
        },
        failFetchWastewaterSystems: (state: DistrictsState, { payload }: PayloadAction<string>) => {
            state.wastewater_systems = [];
            state.loading = false;
            state.error = payload;
        },
    },
    extraReducers: clearOnLogout,
});

export const {
    startFetchDistricts: startFetchDistricts,
    completeFetchDistricts: completeFetchDistricts,
    failFetchDistricts: failFetchDistricts,
    startFetchWastewaterSystems: startFetchWastewaterSystems,
    completeFetchWastewaterSystems: completeFetchWastewaterSystems,
    failFetchWastewaterSystems: failFetchWastewaterSystems,
} = districtsSlice.actions;

export const fetchDistricts = (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(startFetchDistricts());
    try {
        const data = await api.getDistricts();
        dispatch(completeFetchDistricts(data));
    } catch (e: unknown) {
        const message: string = getErrorMessage(e, 'Failed to fetch operating centers.');

        dispatch(failFetchDistricts(message));
    }
};

export const fetchWastewaterSystems =
    (): ThunkType => async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchWastewaterSystems());
        try {
            const data = await api.getWastewaterSystems();
            dispatch(completeFetchWastewaterSystems(data));
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Failed to fetch wastewater systems.');

            dispatch(failFetchWastewaterSystems(message));
        }
    };

export const selectDistricts = ({ districts: districts }: RootState) => districts;

export const createDistrictsOptions = (districts: RootState['districts']) =>
    districts.districts.map(
        (s: DistrictWithPipesCountType): OptionType => ({
            label: `${s.district_name} (${s.pipes} pipes)`,
            value: s.district_name,
            disabled: s.scored_pipes == 0,
        }),
    );
export const selectDistrictOptions = ({ districts: districts }: RootState) =>
    districts.districts.map(
        (s: DistrictWithPipesCountType): OptionType => ({
            label: `${s.district_name} (${s.pipes} pipes)`,
            value: s.district_name,
            disabled: s.scored_pipes == 0,
        }),
    );

export const selectWastewaterSystemOptions = ({ districts: districts }: RootState) =>
    districts.wastewater_systems.map(
        (s: WastewaterSystemWithPipesCountType): WastewaterSystemOptionType => ({
            label: `${s.wastewater_system_name} (${s.pipes} pipes)`,
            value: s.wastewater_system_name,
            district: s.district_name,
            disabled: s.scored_pipes == 0,
        }),
    );

export const selectDistrictsLoading = ({ districts }: RootState) => districts.loading;
export const selectDistrictsError = ({ districts }: RootState) => districts.error;

export default districtsSlice.reducer;
